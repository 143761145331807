import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
import GoogleMapReact from "google-map-react"
import RequestApraisal from "../components/requestApraisal"
// import Helmet from "react-helmet"

const defaultProps = {
  center: {
    lat: 50.720806,
    lng: -1.904755,
  },
  zoom: 15,
}

const AnyReactComponent = ({ country, text }) => (
  <div className="marker">{text}</div>
)

const openLink = link => {
  window.open(link, "_blank")
}

const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <Grid container>
      <Grid container className="contact1">
        <Grid item className="contact1a">
          Contact Us
        </Grid>
      </Grid>

      <Grid container className="grid1_contact">
        <Grid row="true" className="contact2">
          <Grid item className="contact2b">
            <Grid item className="contact2b_request">
              <RequestApraisal
                // updateAppraisalOverlay={updateAppraisalOverlay}
                // closeButton={true}
                // changeAppraisal={changeAppraisal}
                closeButton={false}
              />
            </Grid>
          </Grid>
          <Grid item className="contact2a">
            <Grid container className="contact2a_content">
              <Grid xs={12} item>
                <h1>Westbourne Office</h1>
                <div className="address">
                  <a href="tel:01202 765995">01202 765995</a>
                </div>
                <div className="address">
                  <a
                    className="email_link"
                    href="mailto:sales@brownandkay.co.uk"
                  >
                    sales@brownandkay.co.uk
                  </a>
                </div>
                <div className="address">
                  <a
                    className="email_link"
                    href="mailto:lettings@brownandkay.co.uk"
                  >
                    lettings@brownandkay.co.uk
                  </a>
                </div>

                <h2>Poole Area </h2>
                <div className="address">01202 676292</div>
                <div className="address">
                  <a
                    className="email_link"
                    href="mailto:info@brownandkay.co.uk"
                  >
                    info@brownandkay.co.uk
                  </a>
                </div>
              </Grid>
              <Grid xs={12} item className="contact_item5">
                <h3>Address</h3>
                <div>20 Seamoor Road</div>
                <div>Bournemouth</div>
                <div>BH4 9AR</div>
              </Grid>
              <Grid xs={12} item className="contact_item10">
                <h4>Opening Times</h4>
                <div>Mon-Friday / 08:45 - 18:00</div>
                <div>Sat / 08:45 - 16:00</div>
                <div> Sun / Closed</div>
              </Grid>

              <Grid container className="contact_footer">
                <Grid
                  onClick={() =>
                    openLink(
                      "https://www.facebook.com/BrownandKayPooleandWestbourne/"
                    )
                  }
                  item
                  className="footerlinks6"
                ></Grid>
                <Grid
                  onClick={() =>
                    openLink("https://www.instagram.com/brownandkay/")
                  }
                  item
                  className="footerlinks7"
                ></Grid>
                <Grid
                  onClick={() => openLink("https://twitter.com/BrownandKay/")}
                  item
                  className="footerlinks8"
                ></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="grid3">
        <Grid container className="contact3">
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GATSBY_MAP_API }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={50.72213} lng={-1.90343} text="" />
          </GoogleMapReact>
        </Grid>
      </Grid>
    </Grid>
  </Layout>
  // <div>Hi there</div>
)

export default Contact
